import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppLoading from './views/components/app-loading/app-loading';
import { v1 } from './http/api/v1';
import { ELoadingStatus } from './http/enums';
import { url } from './constants/url';

interface ISessionCheckProps {
  children: React.ReactNode;
}

export const SessionCheck: React.FC<ISessionCheckProps> = ({ children }) => {
  const [loading, setLoading] = useState(ELoadingStatus.Idle);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const response = await v1.session.get();

        if (response.needLogout) {
          navigate(url().logout, { replace: true });
        } else {
          setLoading(ELoadingStatus.Succeeded);
        }
      } catch (error) {
        console.error('Session check failed:', error);
        setLoading(ELoadingStatus.Failed);
      }
    };

    fetchSession();
  }, []);

  if (loading === ELoadingStatus.Loading) {
    return <AppLoading from="sessioncheck" />;
  }

  return <>{children}</>;
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import localTranslationsRU from './i18n/ru.json';
import localTranslationsENG from './i18n/en.json';
import { DEFAULT_LOCALE } from './constants/constants';

i18n.use(initReactI18next).init({
  resources: {
    ru: localTranslationsRU,
    en: localTranslationsENG,
  },
  lng: DEFAULT_LOCALE,
  fallbackLng: DEFAULT_LOCALE,
  interpolation: {
    escapeValue: false,
  },
});
// todo: Добавить обработчик изменения языка, если это необходимо

export default i18n;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import storage from '@storage/index';
import i18n from 'i18next';
import localTranslationsRU from '../i18n/ru.json';
import localTranslationsENG from '../i18n/en.json';
import { ELanguage, ELoadingStatus } from '@http/enums';
import { isEmpty } from 'lodash';
import { v1 } from '@api/v1';
import AppLoading from '@components/app-loading/app-loading';
import Popup from '@components/popup/popup';
import { PopupPortal } from '../views/components/popup-portal/popup-portal';

const UPDATE_INTERVAL = 6 * 60 * 60 * 1000;

export function LocalizationProvider(props: { children?: React.ReactNode }) {
  const dispatch = useDispatch();
  const [mergedTranslations, setMergedTranslations] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const locale = useSelector(storage.localization.selectLocal);
  const loadingStatus = useSelector(storage.localization.selectLoadingStatus);

  const localeHashToSource = {
    [ELanguage.RU]: localTranslationsRU,
    [ELanguage.EN]: localTranslationsENG,
  };

  const updateServerTranslations = async () => {
    const localTranslations = localeHashToSource[locale];
    i18n.addResourceBundle(locale, 'translation', localTranslations, true, true);

    try {
      dispatch(storage.localization.setLoadingStatus(ELoadingStatus.Loading)); // Проверим, вызывается ли это действие
      const response = await v1.glossary.terms.get();

      if (response.errorMsg) {
        dispatch(storage.localization.setLoadingStatus(ELoadingStatus.Failed));
        if (!isOnline) {
          setError('Отсутствует подключение к интернету');
        } else {
          setError(response.errorMsg);
        }

        i18n.addResourceBundle(locale, 'translation', localTranslations, true, true);
        return;
      }

      const serverTranslations: any[] = response.items;

      const mergedTranslationsInner = {
        ...localTranslations,
        ...serverTranslations.reduce(
          (result: any, item: any) => ({
            ...result,
            [item.key]: item.value.replace(/\\n/g, '\n').replace(/\\r/g, '\r'),
          }),
          {},
        ),
      };

      setMergedTranslations(mergedTranslationsInner);
      i18n.addResourceBundle(locale, 'translation', mergedTranslationsInner, true, true);

      dispatch(storage.localization.setLoadingStatus(ELoadingStatus.Succeeded));
    } catch (error) {
      console.error('Error fetching translations:', error);
      dispatch(storage.localization.setLoadingStatus(ELoadingStatus.Failed));
      if (!isOnline) {
        setError('Отсутствует подключение к интернету');
      } else {
        setError('Отсутствует соединение с сервером. Перезагрузите страницу');
      }
      i18n.addResourceBundle(
        locale,
        'translation',
        isEmpty(mergedTranslations) ? localTranslations : mergedTranslations,
        true,
        true,
      );
    } finally {
      if (loadingStatus !== ELoadingStatus.Succeeded) {
        dispatch(storage.localization.setLoadingStatus(ELoadingStatus.Succeeded));
      }
    }
  };

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    const timer = setInterval(() => {
      updateServerTranslations();
    }, UPDATE_INTERVAL);

    return () => {
      clearInterval(timer);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    if (loadingStatus === ELoadingStatus.Idle) {
      updateServerTranslations();
    }
  }, [loadingStatus]);

  if (loadingStatus === ELoadingStatus.Loading || loadingStatus === ELoadingStatus.Idle) {
    return <AppLoading from={'localization'} />;
  }

  return (
    <>
      {props.children}
      {loadingStatus === ELoadingStatus.Failed && error && (
        <PopupPortal>
          <Popup
            callback={() => setError('')}
            showTime={3}
            text={error}
            align={'center'}
            width={'fill'}
          />
        </PopupPortal>
      )}
      {!isOnline && (
        <PopupPortal>
          <Popup
            callback={() => setError('')}
            showTime={3}
            text={'Отсутствует подключение к интернету'}
            align={'center'}
            width={'fill'}
          />
        </PopupPortal>
      )}
    </>
  );
}

import { apiClients } from '@api/index';
import { ApiResponse, ListApiResponse } from '../../../types';
import { ICalendarRequest } from '@models/calendar-request';
import { ICalendarItem } from '@models/calendar-item';

interface ICalendarResponse extends ListApiResponse<ICalendarItem>, ApiResponse {}

export const calendar = {
  get: (request?: ICalendarRequest) => {
    return apiClients.default.get<ICalendarResponse>('v1/calendar/day', { params: request });
  },
};

import {ApiResponse} from "../../../types";
import {apiClients} from "../../";

export interface SystemFeatureResponse extends ApiResponse {
  available?: boolean;
}

export type FeatureName =
  | "UserAuthorizationEmail"
  | "UserAuthorizationSms"
  | "UserAuthorizationVk"
  | "UserAuthorizationGoogle"
  | "UserRegistrationEmail"
  | "UserRegistrationSms"
  | "UserRegistrationVk"
  | "UserRegistrationGoogle"

export const feature = {
  get: (featureName: FeatureName) => {
    return apiClients.default.get<SystemFeatureResponse>(`v1/system/feature/${featureName}`);
  },
}

import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { v1 } from '@api/v1';
import { ELoadingStatus, EUserPermissions } from '@http/enums';

interface IAccessRightsError {
  error?: string;
  errorCode?: number;
}

interface IAccessRightsState extends IAccessRightsError {
  loadingStatus: ELoadingStatus;
  data: EUserPermissions[];
}

const initialState: IAccessRightsState = {
  loadingStatus: ELoadingStatus.Idle,
  data: [],
};

export const loadAccessRights = createAsyncThunk<
  EUserPermissions[],
  void,
  { rejectValue: IAccessRightsError }
>('accessRights/loadAccessRights', async (_, { rejectWithValue }) => {
  try {
    const response = await v1.accessRights.get();

    if (response.errorCode) {
      return rejectWithValue({
        error: response.errorMsg,
        errorCode: response.errorCode,
      });
    }

    return response.permissions;
  } catch (e) {
    return rejectWithValue({
      error: e instanceof Error ? e.message : 'Access Rights Load Error',
    });
  }
});

const accessRightsSlice = createSlice({
  name: 'accessRights',
  initialState,
  reducers: {
    resetData: (state) => {
      state.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadAccessRights.pending, (state) => {
        state.loadingStatus = ELoadingStatus.Loading;
        state.error = undefined;
        state.errorCode = undefined;
      })
      .addCase(loadAccessRights.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loadingStatus = ELoadingStatus.Succeeded;
      })
      .addCase(loadAccessRights.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload.error;
          state.errorCode = action.payload.errorCode;
        } else {
          state.error = 'An unexpected error occurred';
        }
        state.loadingStatus = ELoadingStatus.Failed;
      });
  },
});

const accessRights = {
  ...accessRightsSlice.actions,
  selectError: (state: RootState) => ({
    errorMessage: state.accessRights.error,
    errorCode: state.accessRights.errorCode,
  }),
  selectLoading: (state: RootState) => state.accessRights.loadingStatus,
  selectData: createSelector(
    (state: RootState) => state.accessRights,
    (accessRightsState) => accessRightsState.data,
  ),
};

export const accessRightsReducer = accessRightsSlice.reducer;
export default accessRights;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState, AppThunk } from '../store';
import { ILocation } from '@http/models/location';
import { v1 } from '@api/v1';

interface ILocationListState {
  loading: boolean;
  error?: string;
  items: ILocation[];
}

const initialState: ILocationListState = {
  loading: true,
  items: [],
};

const slice = createSlice({
  name: 'locationList',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setItems: (state, action: PayloadAction<ILocation[]>) => {
      state.items = action.payload;
    },
  },
});
const { setError, setLoading, setItems } = slice.actions;

const locationList = {
  setError,
  setLoading,
  setItems,
  selectItems: (state: RootState) => state.locationList.items,
  loadData: (): AppThunk => async (dispatch) => {
    try {
      dispatch(setError());
      const response = await v1.organization.location.get();
      if (response.errorCode) {
        dispatch(setError(response.errorMsg));
        return;
      }
      dispatch(setItems(response.items));
    } finally {
      dispatch(setLoading(false));
    }
  },
};

export const locationListReducer = slice.reducer;
export default locationList;

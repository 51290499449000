import {ApiResponse} from "../../../types";
import {apiClients} from "../../";

/**
 * Геолокация пользователя
 */
export interface UserGeolocation {
  countryCode?: string;
  countryName?: string;
  regionCode?: string;
  regionName?: string;
  districtCode?: string;
  districtName?: string;
  localityCode?: string;
  localityName?: string;
  schoolCode?: string;
  schoolName?: string;
}

/**
 * Ответ с геолокацией пользователя
 */
interface UserGeolocationResponse extends ApiResponse, UserGeolocation {}

/**
 * Геолокация пользователя
 */
export const userGeolocation =  {

  /**
   * Получить геолокацию текущего пользователя
   */
  get: async (): Promise<UserGeolocationResponse> => {
    return  await apiClients.default.get<UserGeolocationResponse>('v1/user/geolocation');
  },

  /**
   * Сохранить геолокацию текущего пользователя
   */
  post: async (params: UserGeolocation): Promise<ApiResponse> => {
    return  await apiClients.default.post<UserGeolocationResponse>('v1/user/geolocation', params);
  }
}

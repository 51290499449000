import { apiClients } from '@api/index';
import { EventBannerItem, EventMediaItem } from './event';
import { ApiResponse } from '../../../types';
import { EEventType } from '@http/enums';

export interface EventResultResponse extends EventResultItem, ApiResponse {
  medias?: EventMediaItem[];
  results?: EventResultItem[];
}

export interface EventResultItem {
  banners?: EventBannerItem[];
  beginDate?: Date;
  description?: string;
  endDate?: Date;
  eventId?: number;
  eventName?: string;
  eventType?: EEventType;
  eventTypeName?: string;
  id?: number;
  period?: string;
}

export const result = {
  get: (eventId: number) => {
    return apiClients.default.get<EventResultResponse>(`v1/event/${eventId}/result`);
  },
};

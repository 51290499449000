import achievementList from './slices/achievement-list';
import avatarList from './slices/avatar-list';
import calendar from './slices/calendar';
import challengeDetails from './slices/challenge-details';
import challengeList from './slices/challenge-list';
import eventDetails from './slices/event-details';
import eventList from './slices/event-list';
import eventProjectList from './slices/event-project-list';
import eventRating from './slices/event-rating';
import eventResult from './slices/event-result';
import feed from './slices/feed';
import followers from './slices/followers';
import following from './slices/following';
import globalSearch from './slices/global-search';
import hotnews from './slices/hotnews';
import identity from './slices/identity';
import interactive from './slices/interactive';
import localization from './slices/localization';
import locationList from './slices/location-list';
import navigation from './slices/navigation';
import newsDetails from './slices/news-details';
import newsList from './slices/news-list';
import notificationList from './slices/notification-list';
import notificationStatus from './slices/notification-status';
import options from './slices/options';
import profile from './slices/profile';
import projects from './slices/projects';
import quiz from './slices/quiz';
import rubricsList from './slices/rubrics';
import skillDetails from './slices/skill-details';
import skillList from './slices/skill-list';
import userAchievement from './slices/user-achievement';
import userFavoriteProjects from './slices/user-favorite-projects-slice';
import userList from './slices/user-list';
import userProfile from './slices/user-profile';
import createProject from './slices/create-project';
import userProjectList from './slices/user-project-list';
import userSkillList from './slices/user-skill';
import quizResult from './slices/quiz-result';
import accessRights from './slices/access-rights';

export default {
  accessRights,
  achievementList,
  avatarList,
  calendar,
  challengeDetails,
  challengeList,
  createProject,
  eventDetails,
  eventList,
  eventProjectList,
  eventRating,
  eventResult,
  feed,
  followers,
  following,
  globalSearch,
  hotnews,
  identity,
  interactive,
  localization,
  locationList,
  navigation,
  newsDetails,
  newsList,
  notificationList,
  notificationStatus,
  options,
  profile,
  projects,
  quiz,
  quizResult,
  rubricsList,
  skillDetails,
  skillList,
  userAchievement,
  userFavoriteProjects,
  userList,
  userProfile,
  userProjectList,
  userSkillList,
};

import React, { useState, useEffect } from 'react';
import appConfig from '../app-config';

const themes = ['light', 'dark'] as const;

type Theme = (typeof themes)[number];

interface IThemeContext {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

export const ThemeContext = React.createContext<IThemeContext>({
  theme: 'light',
  setTheme: () => {},
});

export const ThemeProvider = (props: { children: React.ReactNode }) => {
  const [theme, setTheme] = useState(getTheme);

  useEffect(() => {
    document.documentElement.dataset.theme = theme;
    localStorage.setItem('theme', theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>{props.children}</ThemeContext.Provider>
  );
};

const getTheme = (): Theme => {
  if (appConfig.theme) {
    if (themes.find((x) => x === appConfig.theme)) {
      return appConfig.theme as Theme;
    }
  }

  const theme = `${window?.localStorage?.getItem('theme')}`;
  if (themes.find((x) => x === theme)) {
    return theme as Theme;
  }
  const userMedia = window.matchMedia('(prefers-color-scheme: dark)');
  if (userMedia.matches) {
    return 'dark';
  }
  return 'light';
};

import { apiClients } from '@api/index';
// todo
import { ApiResponse, ListApiResponse } from '../../../types';
import { Paging } from '../../../../types';
import { ELanguage } from '@http/enums';

interface ITerm {
  id: number;
  key: string;
  value: string;
  language: ELanguage.RU | ELanguage.EN;
}

export interface TermsListResponse extends ListApiResponse<ITerm>, Paging, ApiResponse {}

export const glossary = {
  terms: {
    get: async (): Promise<TermsListResponse> => {
      return await apiClients.default.get<TermsListResponse>(`v1/glossary/term?page=1&take=1500`);
    },
  },
};

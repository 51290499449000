import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { IAchievementItem } from '../../http/models/achievements';
import { v1 } from '@api/v1';

interface IAchievementListState {
  error?: string;
  loading: boolean;
  items: IAchievementItem[];
  total: number;
}

const initialState: { [p: string]: IAchievementListState } = {};

const slice = createSlice({
  name: 'achievementList',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<{ userId: string; error?: string }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? {};
      state[action.payload.userId].error = action.payload.error;
    },
    setLoading: (state, action: PayloadAction<{ userId: string; loading: boolean }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? {};
      state[action.payload.userId].loading = action.payload.loading;
    },
    setItems: (state, action: PayloadAction<{ userId: string; items: IAchievementItem[] }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? {};
      state[action.payload.userId].items = action.payload.items;
    },
    setTotal: (state, action: PayloadAction<{ userId: string; total: number }>) => {
      state[action.payload.userId] = state[action.payload.userId] ?? {};
      state[action.payload.userId].total = action.payload.total;
    },
  },
});

const { setError, setLoading, setItems, setTotal } = slice.actions;

const achievementList = {
  setError: (userId: string, error?: string) => setError({ userId, error }),
  setLoading: (userId: string, loading: boolean) => setLoading({ userId, loading }),
  setItems: (userId: string, items: IAchievementItem[]) => setItems({ userId, items }),
  setTotal: (userId: string, total: number) => setTotal({ userId, total }),
  selectError: (userId: string) => (state: RootState) => state.achievementList[userId]?.error,
  selectLoading: (userId: string) => (state: RootState) =>
    state.achievementList[userId]?.loading ?? true,
  selectItems: (userId: string) => (state: RootState) => state.achievementList[userId]?.items ?? [],
  selectTotal: (userId: string) => (state: RootState) => state.achievementList[userId]?.total ?? 0,
  loadData:
    (userId: string, page: number = 1, take: number = 25): AppThunk =>
    async (dispatch) => {
      dispatch(setLoading({ userId, loading: true }));
      try {
        const response = await v1.user?.USER_ID.achievement.get(userId, { page: 1, take: 500 });
        if (response.errorCode) {
          setError({ userId, error: response.errorMsg });
          return;
        }
        dispatch(setItems({ userId, items: response.items ?? [] }));
        dispatch(setTotal({ userId, total: response.total ?? 0 }));
      } finally {
        dispatch(setLoading({ userId, loading: false }));
      }
    },
};

export const achievementListReducer = slice.reducer;
export default achievementList;

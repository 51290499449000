import { apiClients } from '@api/index';
import { Fetching, ListApiResponse } from '../../../types';
import { Paging } from '../../../../types';
import { IImageItem } from '@models/image-item';
import { ESkillState } from '@http/enums';
import { IAchievementItem } from '@models/achievements';

export interface UserAchievementListRequest extends Paging {
  receivers?: Fetching & { take: number };
  isObjective?: boolean;
}

export interface UserAchievementListResponse extends ListApiResponse<IAchievementItem> {}
export interface UserAchievementItem extends IAchievementItem {}

export interface SkillAchievementItem {
  achievements: IAchievementItem[];
  skillId: number;
  skillName: string;
  skillImage: IImageItem;
  state: ESkillState;
  stateName: string;
}

export const userAchievement = {
  get: (userId: string, request?: UserAchievementListRequest) => {
    return apiClients.default.get<UserAchievementListResponse>(`v1/user/${userId}/achievement`, {
      params: request,
    });
  },
  skill: {
    get: (userId: string) => {
      return apiClients.default.get<ListApiResponse<SkillAchievementItem>>(
        `v1/user/${userId}/skill/achievement`,
      );
    },
  },
};

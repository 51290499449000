import React from 'react';
import Spinner from '../spinner/spinner';
import styles from './app-loading.module.scss';

export default function AppLoading(props: { from?: string }) {
  return (
    <div className={styles['app-fallback']} data-from={props.from}>
      <div className={styles['app-loading']}>
        <Spinner type="rotating-plane" color={'brand-primary'} />
      </div>
    </div>
  );
}

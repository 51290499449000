import { isEmpty } from 'lodash';

import {
  IOrganizationModuleItem,
  IOrganizationModuleOption,
} from '@http/api/v1/organization/organization';

function serializeOption(obj: any) {
  for (let key in obj) {
    if (typeof obj[key] === 'string') {
      const str = obj[key];

      // Преобразование строки в число
      const number = parseFloat(str);
      if (!isNaN(number)) {
        obj[key] = number;
        continue;
      }

      // Преобразование строки в булево значение
      if (str === 'true') {
        obj[key] = true;
        continue;
      } else if (str === 'false') {
        obj[key] = false;
        continue;
      }
    }
  }
  return obj;
}

export const normalizeOrganizationOptions = (
  data: IOrganizationModuleItem[],
): Record<string, IOrganizationModuleItem> => {
  return data.reduce((acc, module) => {
    let optionsObject: Record<string, IOrganizationModuleOption> = {};

    if (module.options && Array.isArray(module.options) && !isEmpty(module.options)) {
      module.options.forEach((option) => {
        optionsObject[option.name] = serializeOption(option);
      });
    }
    acc[module.moduleName] = {
      ...module,
      moduleEnabled: !module.moduleDisabled,
      normalizedOptions: optionsObject,
    };

    return acc;
  }, {} as Record<string, IOrganizationModuleItem>);
};

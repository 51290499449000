import { ListApiResponse } from '../../../types';
import { apiClients } from '@api/index';
import { ESkillSortingType } from '@http/enums';
import { IImageItem } from '@models/image-item';

interface GetSkillTypeListAsync {
  rubricId?: number[];
  sortingType?: ESkillSortingType[];
  page?: number;
  take?: number;
}

interface RubricItem {
  id?: string;
  order?: number;
  name?: string;
}

export interface SkillTypeListItem {
  id?: string;
  order?: number;
  name?: string;
  icon?: string;
  challengeCount?: number;
  rubrics?: RubricItem[];
  image?: IImageItem;
}

interface SkillTypeListResponse extends ListApiResponse<SkillTypeListItem> {}

export const skill = {
  get: async (request: GetSkillTypeListAsync) => {
    const response = await apiClients.default.get<SkillTypeListResponse>('v1/skill', {
      params: request,
    });
    return response;
  },
};

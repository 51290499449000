export const url = () => ({
  skillPage: '/',
  mainPage: '/feed',
  authPage: '/authorization',
  join: '/join',
  rubric: '/rubric',
  calendar: '/calendar',
  welcome: '/welcome',
  challenge: '/challenge',
  login: '/login',
  logout: '/logout',
  search: '/search',
  profile: '/profile',
  licensePolicy: '/license-policy',
  terms: '/terms',
  createProject: '/project/create-project',
});

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState, AppThunk} from '../store';
import auth from "../../http/auth";

interface IIdentityState {
  authorized: boolean;
  nickname?: string;
  userId?: string;
  email?: string;
  role?: string;
}

const claims = auth.getClaims();

const initialState: IIdentityState = {
  authorized: !!auth.access_token,
  nickname: localStorage.getItem('userName') ?? claims?.name,
  userId: claims?.sub,
  email: claims?.email,
  role: claims?.role
};

const slice = createSlice({
  name: "identity",
  initialState,
  reducers: {
    setAuthorized: (state, action: PayloadAction<boolean>) => {
      state.authorized = action.payload;
    },
    setNickname: (state, action: PayloadAction<string|undefined>) => {
      state.nickname = action.payload;
    },
    setUserId: (state, action: PayloadAction<string|undefined>) => {
      state.userId = action.payload;
    },
    setEmail: (state, action: PayloadAction<string|undefined>) => {
      state.email = action.payload;
    },
    setRole: (state, action: PayloadAction<string|undefined>) => {
      state.role = action.payload;
    },
    logout: () => {} // reset all store
  }
});

const {setAuthorized, setNickname, setUserId, setEmail, setRole, logout} = slice.actions;

const identity = {
  setNickname,
  selectAuthorized: (state: RootState) => state.identity.authorized,
  selectNickname: (state: RootState) => state.identity.nickname,
  selectUserId: (state: RootState) => state.identity.userId,
  selectEmail: (state: RootState) => state.identity.email,
  selectRole: (state: RootState) => state.identity.role,
  updateData: (access_token?: string, refresh_token?: string): AppThunk => (dispatch) => {
    if (access_token && refresh_token) {
      auth.set(access_token, refresh_token);
    }
    const claims = auth.getClaims();
    dispatch(setAuthorized(!!auth.access_token));
    dispatch(setNickname(claims?.name));
    dispatch(setUserId(claims?.sub));
    dispatch(setEmail(claims?.email));
    dispatch(setRole(claims?.role));
  },
  logout: (): AppThunk => (dispatch) => {
    auth.logout()
    const claims = auth.getClaims();

    dispatch(setAuthorized(!!auth.access_token));
    dispatch(setNickname(claims?.name));
    dispatch(setUserId(claims?.sub));
    dispatch(setEmail(claims?.email));
    dispatch(setRole(claims?.role));
    dispatch(logout());
    localStorage.removeItem('persist:AppReduxState');
    localStorage.removeItem('deviceId');
    localStorage.removeItem('theme');
  }
}

export const identityReducer = slice.reducer;
export default identity;

import { userAchievement } from './user-achievement';
import { userAvatar } from './user-avatar';
import { userGeolocation } from './user-geolocation';
import { ApiResponse, ListApiResponse } from '@http/types';
import { apiClients } from '@http/api';
import { IUserItem } from '@models/user-item';
import { userUpdates } from './user-updates';
import { userBlocklist } from './user-blocklist';
import { ISkillItem } from '@models/skill-item';
import { IUserReferralCode } from '@models/user-referral-code';
import { userDelete } from '@api/v1/user/user-delete';

export interface UserResponse extends IUserItem, ApiResponse {}

interface IUserListRequest {
  page?: number;
  take?: number;
  search?: string;
  userId?: string;
  state?: ('New' | 'Confirmed' | 'Blocked' | 'Deleted')[];
}

export interface IUserComplaintReasonsResponse extends ApiResponse {}
interface IUserComplaintRequest {
  userId: string;
  complaintUserFormData?: FormData;
}

const NICKNAME = {
  get: async (nickname: string): Promise<UserResponse> => {
    return await apiClients.default.get<UserResponse>(`v1/user/@${nickname}`);
  },
};

const complaint = {
  post: async ({ userId, complaintUserFormData }: IUserComplaintRequest): Promise<ApiResponse> => {
    return await apiClients.default.post<ApiResponse>(
      `v1/user/${userId}/complaint`,
      complaintUserFormData,
    );
  },
};

const referralCode = {
  get: async (): Promise<IUserReferralCode> => {
    return await apiClients.default.get<IUserReferralCode>(`v1/user/referral-code`);
  },
};

const mention = {
  get: async (request: { search: string }): Promise<ListApiResponse<IUserItem>> => {
    return await apiClients.default.get<ListApiResponse<IUserItem>>(`v1/user/mention`, {
      params: request,
    });
  },
};

const skill = {
  get: async (userId: string): Promise<ListApiResponse<ISkillItem>> => {
    return await apiClients.default.get<ListApiResponse<ISkillItem>>(`v1/user/${userId}/skill`);
  },
};

interface IUserListRequest {
  page?: number;
  take?: number;
  search?: string;
  nickname?: string;
  userId?: string;
  state?: ('New' | 'Confirmed' | 'Blocked' | 'Deleted')[];
}

const followers = {
  get: async (request?: IUserListRequest): Promise<ListApiResponse<IUserItem>> => {
    return await apiClients.default.get<ListApiResponse<IUserItem>>(`v1/user/follower`, {
      params: request,
    });
  },
};

const following = {
  get: async (request?: IUserListRequest): Promise<ListApiResponse<IUserItem>> => {
    return await apiClients.default.get<ListApiResponse<IUserItem>>(`v1/user/following`, {
      params: request,
    });
  },
};

export const user = {
  NICKNAME,
  referralCode,
  mention,
  skill,
  delete: userDelete,
  USER_ID: {
    achievement: userAchievement,
    complaint,
  },
  avatar: userAvatar,
  geolocation: userGeolocation,
  updates: userUpdates,
  blocklist: userBlocklist,
  followers,
  following,
  complaint: {
    reason: {
      get: async (): Promise<IUserComplaintReasonsResponse> => {
        return await apiClients.default.get<IUserComplaintReasonsResponse>(
          `v1/user/complaint/reason`,
        );
      },
    },
  },
  get: async (request: IUserListRequest) => {
    return await apiClients.default.get<ListApiResponse<IUserItem>>(`v1/user`, { params: request });
  },
};

import { achievement } from './achievement/achievement';
import { calendar } from './calendar/calendar';
import { challenge } from './challenge/challenge';
import { employees } from './employees/employees';
import { event } from './event/event';
import { external } from './external/external';
import { geolocation } from './geolocation/geolocation';
import { glossary } from './glossary/glossary';
import { identity } from './identity/identity';
import { invite } from './invite/invite';
import { logger } from './logger/logger';
import { notification } from './notification/notification';
import { organization } from './organization/organization';
import { profile } from './profile/profile';
import { project } from './project/project';
import { quiz } from './quiz/quiz';
import { rubrics } from './rubrics/rubrics';
import { skill } from './skill/skill';
import { system } from './system/system';
import { user } from './user/user';
import { session } from './session/session';
import { welcomeVideo } from './video/welcome-video';
import { accessRights } from './access-rights/access-rights';

export const v1 = {
  accessRights,
  achievement,
  calendar,
  challenge,
  employees,
  event,
  external,
  geolocation,
  glossary,
  identity,
  invite,
  logger,
  notification,
  organization,
  profile,
  project,
  quiz,
  rubrics,
  skill,
  system,
  session,
  user,
  welcomeVideo,
};

import {ApiResponse} from "../../../types";
import {apiClients} from "../../";
import {IFormFile} from "../../../form-file";

/**
 * Запрос на создание аватара
 */
interface CreateAvatarRequest {
  file: IFormFile;
}

/**
 * Ответ на создание аватара пользователя
 */
interface CreateAvatarResponse extends ApiResponse {
  id?: string;
  url?: string;
}

/**
 * Аватар полдьзователя
 */
export const userAvatar = {
  post: (request: CreateAvatarRequest) => {
    const formData = new FormData();
    formData.append('file', request.file.blob, request.file.name);
    return apiClients.default.post<CreateAvatarResponse>('v1/user/avatar', formData);
  }
}

import { ELanguage, ELoadingStatus } from '@http/enums';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { DEFAULT_LOCALE } from '@constants/constants';

export const supportedLocales = [ELanguage.EN, ELanguage.RU];

interface ILocalizationState {
  locale: ELanguage;
  loadingStatus: ELoadingStatus;
}

export function setCurrentLocal(locale: ELanguage) {
  if (supportedLocales.includes(locale)) {
    localStorage.setItem('locale', locale);
  } else {
    localStorage.setItem('locale', DEFAULT_LOCALE);
  }
}

const initialState: ILocalizationState = {
  locale: DEFAULT_LOCALE,
  loadingStatus: ELoadingStatus.Idle,
};

const slice = createSlice({
  name: 'localization',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<ELanguage>) => {
      setCurrentLocal(action.payload);
      state.locale = action.payload;
    },
    setLoadingStatus: (state, action: PayloadAction<ELoadingStatus>) => {
      state.loadingStatus = action.payload;
    },
  },
});

const { setLocale, setLoadingStatus } = slice.actions;
const localization = {
  setLocale,
  setLoadingStatus,
  selectLocal: (state: RootState) => state.localization.locale,
  selectLoadingStatus: (state: RootState) => state.localization.loadingStatus,
};

export const localizationReducer = slice.reducer;
export default localization;

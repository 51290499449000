import React, { useEffect, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOptions } from '@storage/slices/options';
import { ELoadingStatus } from '@http/enums';
import AppLoading from '@components/app-loading/app-loading';
import storage from '@storage/index';

interface OptionsLoaderPropsProvider {
  children: ReactNode;
}

export const OptionsLoaderProvider = ({ children }: OptionsLoaderPropsProvider) => {
  const dispatch = useDispatch();
  const status = useSelector(storage.options.selectStatus);
  const error = useSelector(storage.options.selectError);

  useEffect(() => {
    dispatch(fetchOptions());
  }, []);

  if (status === ELoadingStatus.Loading || status === ELoadingStatus.Idle) {
    return <AppLoading from={'OptionsLoaderProvider'} />;
  }

  if (status === ELoadingStatus.Failed) {
    return <div>Ошибка при загрузке app-feature-config-loader: {error}</div>;
  }

  return <>{children}</>;
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { EventResultResponse } from '@api/v1/event/event-result';
import { EBannerType } from '@http/enums';
import { v1 } from '@api/v1';

interface IEventResultState {
  loading: boolean;
  error?: string;
  data: EventResultResponse;
}

const initialState: { [p: string]: IEventResultState } = {};

const createState = (): IEventResultState => ({
  loading: false,
  data: {},
});

const slice = createSlice({
  name: 'eventResult',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<{ id: number; loading: boolean }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].loading = action.payload.loading;
    },
    setError: (state, action: PayloadAction<{ id: number; error?: string }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].error = action.payload.error;
    },
    setData: (state, action: PayloadAction<{ id: number; data?: EventResultResponse }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].data = action.payload.data ?? {};
    },
  },
});

const { setLoading, setError, setData } = slice.actions;

const eventResult = {
  setLoading: (id: number, loading: boolean) => setLoading({ id, loading }),
  setError: (id: number, error?: string) => setError({ id, error }),
  selectError: (id: number) => (state: RootState) => state.eventResult[id]?.error,
  selectLoading: (id: number) => (state: RootState) => state.eventResult[id]?.loading ?? true,
  selectData: (id: number) => (state: RootState) => state.eventResult[id]?.data ?? {},
  selectMainBanner: (id: number) => (state: RootState) =>
    state.eventResult[id]?.data?.banners?.find((x) => x.type === EBannerType.Main) ?? {},
  selectResultBanner: (id: number) => (state: RootState) =>
    state.eventResult[id]?.data?.banners?.find((x) => x.isEventResultBanner) ?? {},
  loadData:
    (id: number): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(setError({ id }));
        const response: EventResultResponse = await v1.event.id.result.get(id);
        if (response.errorCode) {
          dispatch(setError({ id, error: response.errorMsg }));
          return;
        }
        dispatch(setData({ id, data: response }));
      } finally {
        dispatch(setLoading({ id, loading: false }));
      }
    },
};

export const eventResultReducer = slice.reducer;
export default eventResult;

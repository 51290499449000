import { apiClients } from '@http/api';
import { ApiResponse } from '@http/types';

interface RegisterVideoResponse extends ApiResponse {
  url: string;
}

export const welcomeVideo = {
  video: {
    post: () => {
      return apiClients.default.post<ApiResponse>('v1/welcome/video/view');
    },
    get: () => {
      return apiClients.default.get<RegisterVideoResponse>('v1/welcome/video');
    },
  },
};

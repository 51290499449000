import { ApiResponse } from '@http/types';
import { apiClients } from '@api/index';

interface RegisterUserRequest {
  sessionId?: string;
  completeRegistration?: boolean;
  nickname?: string;
  email?: string;
  phone?: string;
  password?: string;
  birthYear?: number;
  referralCode?: string;
  inviteLinkId?: string;
}

interface RegistrationConfirmRequest {
  sessionId?: string;
  contact?: string;
  code?: string;
}

interface SendRegistrationConfirmCodeRequest {
  sessionId?: string;
  contact?: string;
  channel?: 'Email' | 'Sms' | 'MissingCall';
}

export interface IAuthorizationData {
  access_token?: string;
  refresh_token?: string;
}

export interface IRegisterUser {
  sessionId?: string;
}

export interface ISendRegistrationConfirmCodeData {
  remainingAttempts?: number;
}

interface AuthorizationDataResponse extends ApiResponse, IAuthorizationData {}

interface RegisterUserResponse extends ApiResponse, IRegisterUser {}

interface SendRegistrationConfirmCodeResponse
  extends ApiResponse,
    ISendRegistrationConfirmCodeData {}

export const identityRegistration = {
  post: (request: RegisterUserRequest) => {
    return apiClients.default.post<RegisterUserResponse>('v1/identity/registration', request);
  },
  confirm: {
    post: (request: RegistrationConfirmRequest) => {
      return apiClients.default.post<AuthorizationDataResponse>(
        'v1/identity/registration/confirm',
        request,
      );
    },
  },
  confirmCode: {
    send: {
      post: (request: SendRegistrationConfirmCodeRequest) => {
        return apiClients.default.post<SendRegistrationConfirmCodeResponse>(
          'v1/identity/registration/confirm-code/send',
          request,
        );
      },
    },
  },
};

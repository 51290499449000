import { ApiResponse } from '../../../types';
import { apiClients } from '@api/index';
import { IEmployeeProfile } from '@models/employee-profile';
import { ISubdivision } from '@models/subdivision';

interface EmployeeProfileResponse extends ApiResponse, IEmployeeProfile {}

interface UpdateEmployeeProfileRequest {
  firstName?: string;
  lastName?: string;
  educationalInstitution?: string;
  birthdayDate?: string;
  locationId?: number;
  subdivisionId?: number;
  direction?: string;
  category?: string;
  subdivisions?: ISubdivision[];
}

export const employeesProfile = {
  get: () => {
    return apiClients.default.get<EmployeeProfileResponse>('v1/employees/profile');
  },
  put: (request: UpdateEmployeeProfileRequest) => {
    return apiClients.default.put<EmployeeProfileResponse>('v1/employees/profile', request);
  },
};

import {generateUUID} from "./uuid";

/**
 * Получить идентификатор устройства
 */
export const getDeviceId = () => {
  let deviceId = localStorage.getItem('deviceId');
  if (deviceId) {
    return deviceId;
  }
  deviceId = generateUUID();
  localStorage.setItem('deviceId', deviceId);
  return deviceId;
}

import { ApiResponse, ListApiResponse } from '@http/types';
import { apiClients } from '@api/index';

export interface IDeleteReasonItem {
  id: number;
  text: string;
}

export interface IDeleteReasonResponse extends ListApiResponse<IDeleteReasonItem>, ApiResponse {}

export const userDelete = {
  reason: {
    get: async () => apiClients.default.get<IDeleteReasonResponse>('v1/user/delete/reason'),
  },
};

import { batch } from 'react-redux';
import { uniqBy } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IProjectItem } from '@http/models/project-item';
import { RootState, AppThunk } from '../store';
import { v1 } from '@api/v1';

interface IUserFavoriteProjectsState {
  error?: string;
  loading: boolean;
  items: IProjectItem[];
  total: number;
  page: number;
}

const initialState: IUserFavoriteProjectsState = {
  loading: true,
  items: [],
  total: 0,
  page: 1,
};

const slice = createSlice({
  name: 'userFavoriteProjects',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setItems: (state, action: PayloadAction<IProjectItem[]>) => {
      state.items = action.payload;
    },
    deleteItem: (state, action: PayloadAction<number>) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    addItems: (state, action: PayloadAction<IProjectItem[]>) => {
      state.items = uniqBy([...state.items, ...action.payload], ({ id }) => id);
    },
    reset: () => initialState,
  },
});

const { setLoading, setError, addItems, setTotal, setPage, setItems, reset, deleteItem } =
  slice.actions;

const userFavoriteProjects = {
  deleteItem,
  reset,
  setItems,
  setLoading,
  setError,
  addItems,
  setPage,
  selectLoading: (state: RootState) => state.userFavoriteProjects.loading,
  selectError: (state: RootState) => state.userFavoriteProjects.error,
  selectItems: (state: RootState) => state.userFavoriteProjects.items,
  selectTotal: (state: RootState) => state.userFavoriteProjects.total,
  loadItems:
    (loadMore?: boolean): AppThunk =>
    async (dispatch, getState) => {
      const state = getState();
      dispatch(setError());

      try {
        const response = await v1.project.get({
          userLiked: true,
          medias: { fetch: true },
          page: loadMore ? state.userFavoriteProjects.page + 1 : 1,
          take: 36,
        });

        if (response.errorCode) {
          dispatch(setError(response.errorMsg));
          return;
        }
        batch(() => {
          response.items &&
            dispatch(loadMore ? addItems(response.items) : setItems(response.items));
          response.total && dispatch(setTotal(response.total));
          dispatch(setPage(loadMore ? state.userFavoriteProjects.page + 1 : 1));
        });
      } finally {
        dispatch(setLoading(false));
      }
    },
};

export const userFavoriteProjectsReducer = slice.reducer;
export default userFavoriteProjects;

import parser from 'ua-parser-js';

interface IBrowser {
  readonly name: string | undefined;
  readonly version: string | undefined;

  readonly major: string | undefined;
}

interface IDevice {
  readonly model: string | undefined;
  readonly type: string | undefined;
  readonly manufacturer: string | undefined;
}

interface IEngine {
  readonly name: string | undefined;
  readonly version: string | undefined;
}

interface IOS {
  readonly name: string | undefined;
  readonly version: string | undefined;
}

interface ICPU {
  readonly architecture: string | undefined;
}

interface IUserAgentData {
  readonly ua: string;
  readonly browser: IBrowser;
  readonly device: IDevice;
  readonly engine: IEngine;
  readonly os: IOS;
  readonly cpu: ICPU;
}

const userAgentData: IUserAgentData = (() => {
  const data = parser(window.navigator.userAgent);

  return {
    ua: data.ua,
    browser: data.browser,
    device: {
      model: data.device.model,
      type: data.device.type,
      manufacturer: data.device.vendor,
    },
    engine: data.engine,
    os: data.os,
    cpu: data.cpu,
  };
})();

export default userAgentData;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import { NewsItem } from '@api/v0_1/news/news';
import { v0_1 } from '@api/v0_1';

interface INewsState {
  loading: boolean;
  error?: string;
  data: NewsItem;
}

const initialState: { [p: number]: INewsState } = {};

const createState = (): INewsState => ({
  loading: false,
  data: {},
});

const slice = createSlice({
  name: 'newsDetails',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<{ id: number; loading: boolean }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].loading = action.payload.loading;
    },
    setError: (state, action: PayloadAction<{ id: number; error?: string }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].error = action.payload.error;
    },
    setData: (state, action: PayloadAction<{ id: number; data: NewsItem }>) => {
      state[action.payload.id] = state[action.payload.id] ?? createState();
      state[action.payload.id].data = action.payload.data ?? {};
    },
  },
});

const { setLoading, setError, setData } = slice.actions;

const newsDetails = {
  setLoading: (id: number, loading: boolean) => setLoading({ id, loading }),
  setError: (id: number, error?: string) => setError({ id, error }),
  selectError: (id: number) => (state: RootState) => state.newsDetails[id]?.error,
  selectLoading: (id: number) => (state: RootState) => state.newsDetails[id]?.loading ?? true,
  selectData: (id: number) => (state: RootState) => state.newsDetails[id]?.data ?? {},
  loadData:
    (id: number): AppThunk =>
    async (dispatch) => {
      try {
        dispatch(setError({ id }));
        const response = await v0_1.news.ID.get(id);
        if (response.errorCode) {
          dispatch(setError({ id, error: response.errorMsg }));
          return;
        }
        dispatch(setData({ id, data: response }));
      } finally {
        dispatch(setLoading({ id, loading: false }));
      }
    },
};

export const newsDetailsReducer = slice.reducer;
export default newsDetails;

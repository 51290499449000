import { ApiResponse } from '@http/types';
import { apiClients } from '@api/index';

export interface GeolocationResponse extends ApiResponse {
  countryCode?: string;
}

interface IGeolocationRequest {
  search?: string;
  page?: number;
  take?: number;
  //
  countryCode?: string;
  regionCode?: string;
  localityCode?: string;
  schoolName?: string;
}

export const geolocation = {
  /**
   * Получить текущую геолокацию
   */
  get: () => {
    return apiClients.default.get<GeolocationResponse>('v1/geolocation');
  },
  country: {
    get: (request: IGeolocationRequest) => {
      return apiClients.apiService.get<any>('v1/geolocation/country', {
        params: request,
      });
    },
  },
  region: {
    get: (request: IGeolocationRequest) => {
      return apiClients.apiService.get<any>('v1/geolocation/region', {
        params: request,
      });
    },
  },
  locality: {
    get: (request: IGeolocationRequest) => {
      return apiClients.apiService.get<any>('v1/geolocation/locality', {
        params: request,
      });
    },
  },
  school: {
    get: (request: IGeolocationRequest) => {
      return apiClients.apiService.get<any>('v1/geolocation/school', {
        params: request,
      });
    },
  },
};

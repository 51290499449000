export const normalizeObject = <T extends Record<string, any>>(obj: T) => {
  const params: { [p: string]: any } = {};

  for (const [key, value] of Object.entries(obj)) {
    if (value === undefined) {
      continue;
    }
    if (Array.isArray(value) || typeof value != 'object') {
      params[key] = value;
      continue;
    }

    for (const [k, v] of Object.entries(value)) {
      if (v === undefined) {
        continue;
      }
      if (Array.isArray(v) && v.length === 0) {
        continue;
      }
      if (Array.isArray(v) || typeof v != 'object') {
        params[`${key}.${k}`] = v;
      }
    }
  }

  return params;
};

import { challenges } from './challenges/challenges';
import { followers } from './followers/followers';
import { following } from './following/following';
import { hotnews, news } from './news/news';
import { projects } from './projects/projects';
import { user } from './user/user';
import { users } from './users/users';
import { comments } from './comments/comments';
import { rubrics } from './rubrics/rubrics';
import { skillTypes } from './skill-types/skill-types';

export const v0_1 = {
  challenges,
  followers,
  comments,
  following,
  news,
  hotnews,
  projects,
  rubrics,
  skillTypes,
  user,
  users,
};

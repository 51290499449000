import { IProjectItem } from '@models/project-item';
import { IProjectListRequest } from '@models/project-list-request';
import { IProjectList } from '@models/project-list';
import { apiClients } from '@api/index';
import { EProjectModerationStatus } from '@http/enums';
import { parseToEnum } from '@utils/type-parser';

export const project = {
  get: async (request: IProjectListRequest) => {
    const response = await apiClients.default.get<IProjectList>(`v2/project`, {
      params: request,
    });

    if (response && response.items) {
      (response.items as IProjectItem[]).map((item: IProjectItem) => {
        item.status = parseToEnum(EProjectModerationStatus, item.status);
        return item;
      });
    }

    return response;
  },
};

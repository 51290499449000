import React, { useEffect, useState, useRef } from 'react';
import { BottomSheetContext } from '@providers/bottom-sheet-provider';

import styles from './bottom-sheet.module.scss';

export const BottomSheet = (props: any) => {
  const { content, hide, visible } = React.useContext(BottomSheetContext);
  const ref = useRef<HTMLDivElement>(null);

  const [initialY, setInitialY] = useState(0);
  const [currentY, setCurrentY] = useState(0);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setInitialY(e.touches[0].clientY);
    setCurrentY(e.touches[0].clientY);
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    setCurrentY(e.touches[0].clientY);
    if (e.touches[0].clientY < initialY) {
      setCurrentY(initialY);
    }
  };

  const handleTouchEnd = () => {
    if (currentY - initialY > 70) {
      hide();
    }
  };

  const handleSlideDown = () => {
    hide();
  };

  const handleTransitionEnd = () => {
    if (!visible) {
      setCurrentY(0);
      setInitialY(0);
    }
  };

  useEffect(() => {
    if (visible) {
      document.body.classList.add('prevent-scroll');
    } else {
      document.body.classList.remove('prevent-scroll');
    }

    if (!visible) {
      hide();
    }
    return () => {
      document.body.classList.remove('prevent-scroll');
    };
  }, [hide, visible]);

  const handleClickOutside = (e: any) => {
    if (ref && 'current' in ref && ref.current && !ref.current.firstChild?.contains(e.target)) {
      hide();
    }
  };

  return (
    <div className={styles.container} onClick={handleClickOutside} ref={ref}>
      <div
        className={`${styles.sheet} ${visible ? styles.visible : styles.hidden}`}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onTransitionEnd={handleTransitionEnd}
        style={{ transform: `translateY(${visible ? currentY - initialY : 0}px)` }}
      >
        <div className={styles.handle} onTouchEnd={handleSlideDown} />
        <div className={styles.content}>{content}</div>
      </div>
    </div>
  );
};

import { parseISO } from 'date-fns';

export function getISODate(dateWithTimezone: Date) {
  const parsedDate =
    typeof dateWithTimezone === 'string' ? parseISO(dateWithTimezone) : dateWithTimezone;

  const year = parsedDate.getFullYear();
  const month = parsedDate.getMonth(); // 0-based index (May is 4)
  const day = parsedDate.getDate();
  const hours = 0;
  const minutes = 0;
  const seconds = 0;
  const milliseconds = 0;

  // create new Date object without timezone
  const date = new Date(Date.UTC(year, month, day, hours, minutes, seconds, milliseconds));

  return date.toISOString();
}

import React, { useState, useEffect, useMemo, useCallback } from 'react';

import AppLoading from '@components/app-loading/app-loading';
import { DEFAULT_SCHEME } from '@constants/constants';
import { ECompanyNames, ELoadingStatus } from '@http/enums';
import { v1 } from '@api/v1';
import { Capacitor } from '@capacitor/core';

interface ISchemeContext {
  isRoundFamily?: boolean;
  scheme?: string;
  title?: string;
  vkId?: string;
}

export const SchemeContext = React.createContext<ISchemeContext>({
  scheme: undefined,
});

export const SchemeProvider = (props: { children: React.ReactNode }) => {
  const isDevelopment = process.env.NODE_ENV === 'development';
  const isNative = Capacitor.isNativePlatform();
  const [scheme, setScheme] = useState<string | undefined>(undefined);
  const [vkId, setVkId] = useState<string | undefined>(undefined);
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [schemeLoadingStatus, setSchemeLoadingStatus] = useState<ELoadingStatus>(
    ELoadingStatus.Idle,
  );

  const setSchemeToApp = useCallback((schemeTo: string) => {
    document.documentElement.dataset.scheme = schemeTo;
    localStorage.setItem('scheme', schemeTo);
    setScheme(schemeTo);
  }, []);

  const isRoundFamily = useMemo(() => {
    return (
      scheme === DEFAULT_SCHEME ||
      scheme === ECompanyNames.Demo ||
      scheme === ECompanyNames.DemoEnglish
    );
  }, [scheme]);

  useEffect(() => {
    const fetchAndStoreScheme = async () => {
      if (isDevelopment || isNative) {
        setSchemeToApp(process.env.REACT_APP_SCHEME!);
      }
      try {
        const response = await v1.organization.scheme.get();

        if (response?.errorCode) {
          await v1.logger.post({
            level: 'Error',
            message: `errorCode=${response.errorCode} errorMsg=${response.errorMsg}`,
          });
          if (response?.errorCode === 404 || response?.errorCode === 500) {
            setSchemeLoadingStatus(ELoadingStatus.Failed);
          }
          return;
        }

        const { code, name, vkId } = response;

        if (code) {
          setSchemeToApp(code);
        }
        if (name) {
          setTitle(name);
        }
        if (vkId) {
          setVkId(vkId);
        }
        setSchemeLoadingStatus(ELoadingStatus.Succeeded);
      } catch (error) {
        console.error('Error fetching organization scheme:', error);
        setSchemeLoadingStatus(ELoadingStatus.Failed);
      }
    };

    fetchAndStoreScheme();
  }, []);

  if (schemeLoadingStatus !== ELoadingStatus.Succeeded) {
    return <AppLoading from="scheme-provider" />;
  }

  return (
    <SchemeContext.Provider value={{ scheme, isRoundFamily, title, vkId }}>
      {props.children}
    </SchemeContext.Provider>
  );
};

import { ApiResponse, ListApiResponse } from '@http/types';
import { apiClients } from '@api/index';
import { ILocation } from '@models/location';
import { ISubdivision } from '@models/subdivision';

export interface IOrganizationResponse {
  code: string;
  style: string;
  name: string;
  vkId: string;
  errorCode?: number;
  errorMsg?: string;
}

export interface IOrganizationModuleOption {
  id: number;
  name: string;
  type: number;
  value: string | number | boolean;
  defaultValue: string | number | boolean;
}

export interface IOrganizationModuleItem {
  id: number;
  moduleName: string;
  moduleDisabled: boolean;
  moduleEnabled: boolean;
  options?: IOrganizationModuleOption[];
  normalizedOptions?: Record<string, IOrganizationModuleOption>;
}

export interface IOrganizationDocument {
  publicationRules: string;
  licensingPolicy: string;
  externalLibraries: string;
}

export interface IOrganizationModuleResponse extends ListApiResponse<IOrganizationModuleItem> {}

export interface ILocationResponse extends ListApiResponse<ILocation>, ApiResponse {}

export interface IDocumentResponse extends IOrganizationDocument, ApiResponse {}
export interface ISubdivisionResponse extends ListApiResponse<ISubdivision>, ApiResponse {}

export const organization = {
  scheme: {
    get: async () => await apiClients.default.get<IOrganizationResponse>('v1/organization/scheme'),
  },
  moduleOption: {
    get: async () =>
      await apiClients.default.get<IOrganizationModuleResponse>('v1/organization/module/option'),
  },
  location: {
    get: () => {
      return apiClients.default.get<ILocationResponse>('v1/organization/location', {
        params: { take: 50 },
      });
    },
  },
  document: {
    get: async () => await apiClients.default.get<IDocumentResponse>('/v1/organization/document'),
  },
  subdivision: {
    get: async ({ parentId }: { parentId: number }) =>
      await apiClients.default.get<ISubdivisionResponse>('/v1/organization/subdivision', {
        params: { parentId: parentId || 0, take: 100 },
      }),
  },
};

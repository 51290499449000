import React from 'react';
import { SchemeProvider } from './scheme-provider';
import { ThemeProvider } from './theme-provider';
import { LocalizationProvider } from './localization-provider';
import { BottomSheetProvider } from './bottom-sheet-provider';
import { OptionsLoaderProvider } from './options-loader-provider';
import { PopupProvider } from './PopupProvider';
import { AppTrackingTransparencyProvider } from './AppTrackingTransparencyProvider';
import { ProfileLoaderProvider } from './ProfileLoaderProvider';
import { AccessRightsLoaderProvider } from './AccessRightsLoaderProvider';

export const AppProviders = ({ children }: { children: React.ReactNode }) => {
  return (
    <SchemeProvider>
      <ThemeProvider>
        <LocalizationProvider>
          <OptionsLoaderProvider>
            <AccessRightsLoaderProvider>
              <ProfileLoaderProvider>
                <AppTrackingTransparencyProvider>
                  <PopupProvider>
                    <BottomSheetProvider>{children}</BottomSheetProvider>
                  </PopupProvider>
                </AppTrackingTransparencyProvider>
              </ProfileLoaderProvider>
            </AccessRightsLoaderProvider>
          </OptionsLoaderProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </SchemeProvider>
  );
};

import { apiClients } from '@api/index';
import { ApiResponse, Fetching, ListApiResponse } from '../../../types';
import { EBannerType, EDifficulty, EMediaType, EChallengeSpeed } from '@http/enums';
import { Paging } from '../../../../types';
import { rating } from './event-rating';
import { subscribe } from './event-subscribe';
import { result } from './event-result';
import { ISkillItem } from '@models/skill-item';
import { IEventItem } from '@models/event-item';
import { IImageItem } from '@models/image-item';

interface GetEventListRequest extends Paging {
  id?: string[];
  hasParent?: boolean;
  hasChildren?: boolean;
  banners?: Fetching;
  prizes?: Fetching;
  challenges?: Fetching;
}

export interface EventListResponse extends ListApiResponse<IEventItem> {}

export interface EventBannerItem {
  type?: EBannerType;
  isEventResultBanner?: boolean;
  url?: string;
  previewBase64?: string;
}

export interface EventPrizeItem {
  id?: string;
  count?: number;
  countFormatted?: string;
  name?: string;
  description?: string;
  image?: IImageItem;
}

export interface EventChallengeItem {
  id?: number;
  name?: string;
  difficulty?: EDifficulty;
  difficultyName?: string;
  speed?: EChallengeSpeed;
  speedName?: string;
  experience?: number;
  image?: IImageItem;
  skills?: ISkillItem[];
}

export interface EventResponse extends IEventItem, ApiResponse {}

export interface EventMediaItem {
  type?: EMediaType;
  typeName?: string;
  url?: string;
  previewBase64?: string;
}

export const event = {
  get: (request: GetEventListRequest) => {
    return apiClients.default.get<EventListResponse>('v1/event', { params: request });
  },
  id: {
    get: (eventId: number) => {
      return apiClients.default.get<EventResponse>(`v1/event/${eventId}`);
    },
    result,
    rating,
    subscribe,
  },
};

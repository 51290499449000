import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface INavigationState {
  hidden: boolean;
}

const initialState: INavigationState = {
  hidden: false,
};

const slice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setHidden: (state, action: PayloadAction<boolean>) => {
      state.hidden = action.payload;
    },
  },
});

const navigation = {
  ...slice.actions,
  selectHidden: (state: RootState) => state.navigation.hidden,
};

export const navigationReducer = slice.reducer;
export default navigation;

import React, { useMemo } from 'react';
import styles from './spacing.module.scss';

interface SpacingProps {
  size: 'h4' | 'h8' | 'h12' | 'h16' | 'h20' | 'h22' | 'h24' | 'h28' | 'h30' | 'h32' | 'h50' | 'h64' | 'fill';
}

export default function Spacing(props: SpacingProps) {
  const className = useMemo(() => `${styles.container} ${styles[props.size]}`, [props.size]);
  return <div className={className} />;
}

import { ApiResponse } from '../../../types';
import { apiClients } from '@api/index';

interface UserBlocklistResponse extends ApiResponse {}

export const userBlocklist = {
  post: async (id: string) => {
    return await apiClients.default.post<UserBlocklistResponse>(`v1/user/${id}/blocklist`);
  },
  delete: async (id: string) => {
    return await apiClients.default.delete<UserBlocklistResponse>(`v1/user/${id}/blocklist`);
  },
};

import userAgentData from './utils/user-agent-data';
import { getDeviceId } from './utils/device';

interface IOS {
  readonly name?: string;
  readonly version?: string;
}

interface IClient {
  readonly id?: string;
  readonly name?: string;
  readonly version?: string;
}

interface IDevice {
  readonly id?: string;
  readonly type?: string;
  readonly model?: string;
  readonly manufacturer?: string;
}

interface ICPU {
  readonly architecture: string | undefined;
}

interface IAppContext {
  readonly os: IOS;
  readonly client: IClient;
  readonly device: IDevice;
  readonly cpu: ICPU;
}

const appContext: IAppContext = {
  os: {
    ...userAgentData.os,
  },
  client: {
    id: 'round-web-app',
    ...userAgentData.browser,
  },
  device: {
    id: getDeviceId(),
    ...userAgentData.device,
  },
  cpu: {
    ...userAgentData.cpu,
  },
};

export default appContext;

import { ApiResponse, ListApiResponse } from '../../../types';
import { Paging } from '../../../../types';
import { apiClients } from '@api/index';
import { INotificationItem } from '@models/notification-item';
import { INotificationStatus } from '@models/notification-status';

export interface NotificationListResponse extends ListApiResponse<INotificationItem> {}

export interface NotificationsStatusResponse extends INotificationStatus, ApiResponse {}

export const notification = {
  get: async (request: Paging) => {
    return await apiClients.default.get<NotificationListResponse>(`v1/notification`, {
      params: request,
    });
  },
  status: {
    get: async () => {
      return await apiClients.default.get<NotificationsStatusResponse>(`v1/notification/status`);
    },
  },
};

/**
 * Преобразование исходного значения в значение перечисления.
 * @param enumType - тип перечисления.
 * @param value - исходное значение.
 * @return - значение перечисления или исходное значение.
 */
export const parseToEnum = (enumType: object, value: any): any => {

  if ( typeof value == "number"
    || typeof value == "string") {

    const result = (enumType as any)[value];
    if ( typeof result == "number"
      || typeof result == "string") {

      return result;
    }
  }

  return value;
}

import { apiClients } from '@api/index';
import { ISubUserItem } from '@models/subscription-user';

export const users = {
  USER_ID: {
    following: {
      get: async (id?: string) => {
        return await apiClients.default.get<ISubUserItem[]>(`users/${id}/following`);
      },
    },
    followers: {
      get: async (id?: string) => {
        return await apiClients.default.get<ISubUserItem[]>(`users/${id}/followers`);
      },
    },
  },
};

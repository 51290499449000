import { ApiResponse } from '../../../types';
import { apiClients } from '@api/index';
import { userAvatar } from './user-avatar';

export interface CreateUserRequest {
  email?: string;
  phone?: string;
  nickname?: string;
  birthdayYear?: number;
  password?: string;
  referralCode?: string;
  validationOnly?: boolean;
  validationFields?: string[];
}

export interface ICreateUser {
  sessionId?: string;
}

interface CreateUserResponse extends ApiResponse, ICreateUser {}

export const user = {
  avatar: userAvatar,
  post: async (request: CreateUserRequest): Promise<CreateUserResponse> => {
    return await apiClients.default.post<CreateUserResponse>('user', request);
  },
};

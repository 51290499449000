/**
 * Также в профиле есть флаг registrationIsOpen
 * там будет приходить тоже значение, что и в needLogout
 */
import { apiClients } from '@api/index';

interface ISessionResponse {
  needLogout: boolean;
}

export const session = {
  get: async () => await apiClients.default.get<ISessionResponse>('v1/session'),
};

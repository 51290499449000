import { createRoot } from 'react-dom/client';
import React from 'react';
import App from './app';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import store, { persistor } from './storage/store';
import { PersistGate } from 'redux-persist/integration/react';
import AppLoading from '@components/app-loading/app-loading';
import './i18n';

import './index.scss';

const container = document.getElementById('root');

const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <PersistGate loading={<AppLoading />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { identityRegistration } from './identity-registration';
import { identityExternal } from './identity-external';
import { ApiResponse, ListApiResponse } from '@http/types';
import { EPrivacySettingsName } from '@http/enums';
import { apiClients } from '@api/index';

export interface IDeleteUserRequest {
  deleteReason?: string;
  deleteReasonText?: string;
}

export interface IDropdownItem {
  id: number;
  name: string;
  value: EPrivacySettingsName;
}

export interface IDropdownResponse extends ListApiResponse<IDropdownItem>, ApiResponse {}

export const identity = {
  registration: identityRegistration,
  external: identityExternal,
  delete: async (request: IDeleteUserRequest) => {
    const formData = new FormData();
    if (request.deleteReason) {
      formData.append('deleteReason', request.deleteReason);
    }
    if (request.deleteReasonText) {
      formData.append('deleteReasonText', request.deleteReasonText);
    }

    return await apiClients.default.delete<ApiResponse>('v1/identity/reason/term/user', {
      data: request,
    });
  },
  dropdown: {
    get: (type: string) => {
      return apiClients.default.get<IDropdownResponse>('v1/identity/dropdown', {
        params: { type },
      });
    },
  },
};

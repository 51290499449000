import { ApiResponse } from '../../../types';
import { apiClients } from '@api/index';
import { ISubUserItem } from '@models/subscription-user';

export const following = {
  get: async () => {
    return await apiClients.default.get<ISubUserItem[]>('following');
  },
  post: async (userId?: string) => {
    return await apiClients.default.post<ApiResponse>('following', null, {
      params: { id: userId },
    });
  },
  delete: async (userId?: string) => {
    return await apiClients.default.delete<ApiResponse>('following', { params: { id: userId } });
  },
};

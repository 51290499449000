import { NewsItem } from './../../http/api/v0_1/news/news';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import { batch } from 'react-redux';
import { v0_1 } from '@api/v0_1';

export const HOTNEWS_KEY = 'hotnews';

function getSeenNewsId(userId: string) {
  const seenNews = localStorage.getItem(HOTNEWS_KEY);

  return !!seenNews && typeof seenNews !== 'number' ? JSON.parse(seenNews)[userId] : undefined;
}

function updateSeenNewsId(userId: string, newSeenId: number) {
  const seenNews = localStorage.getItem(HOTNEWS_KEY);
  let updatedSeenNews;

  if (seenNews) {
    updatedSeenNews = JSON.parse(seenNews);
    updatedSeenNews[userId] = newSeenId;
  } else {
    updatedSeenNews = { [userId]: newSeenId };
  }

  localStorage.setItem(HOTNEWS_KEY, JSON.stringify(updatedSeenNews));
}

interface IHotnewsState {
  loading: boolean;
  error?: string;
  data?: NewsItem;
}

const initialState: IHotnewsState = {
  loading: false,
};
const slice = createSlice({
  name: 'hotnews',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setData: (state, action: PayloadAction<NewsItem | undefined>) => {
      state.data = action.payload;
    },
    resetHotnews: () => {
      return initialState;
    },
  },
});
const { setLoading, setError, setData, resetHotnews } = slice.actions;

const hotnews = {
  resetHotnews,
  setLoading,
  setError,
  setData,
  selectLoading: (state: RootState) => state.hotnews.loading,
  selectError: (state: RootState) => state.hotnews.error,
  selectData: (state: RootState) => state.hotnews.data,
  loadData: (): AppThunk => async (dispatch, getState) => {
    try {
      const userId = getState().identity.userId;
      batch(() => {
        dispatch(setError());
        dispatch(setLoading(true));
      });

      const response = await v0_1.hotnews.get();
      const newsItem = (response as NewsItem[])[0] ?? [];

      if (newsItem?.id && userId) {
        const newsId = getSeenNewsId(userId);

        if (newsId && newsId === newsItem.id) {
          dispatch(resetHotnews());

          return;
        } else {
          updateSeenNewsId(userId, newsItem.id);
          dispatch(setData(newsItem));
        }
      }
    } finally {
      dispatch(setLoading(false));
    }
  },
};

export const hotnewsReducer = slice.reducer;
export default hotnews;

import { apiClients } from '@api/index';
import { ListApiResponse } from '../../../types';
import { IRubricItem } from '@models/rubric-item';

export interface RubricsListResponse extends ListApiResponse<IRubricItem> {}

export const rubrics = {
  get: async () => {
    return await apiClients.default.get<RubricsListResponse>('v1/rubric');
  },
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ELoadingStatus } from '@http/enums';
import storage from '@storage/index';
import { PopupPortal } from '@components/popup-portal/popup-portal';
import Popup from '@components/popup/popup';
import { loadAccessRights } from '@storage/slices/access-rights';

interface IAccessRightsLoaderProps {
  children: React.ReactNode;
}

export const AccessRightsLoaderProvider: React.FC<IAccessRightsLoaderProps> = ({ children }) => {
  const dispatch = useDispatch();

  const loading = useSelector(storage.accessRights.selectLoading);
  const error = useSelector(storage.accessRights.selectError);

  useEffect(() => {
    dispatch(loadAccessRights());
  }, []);

  return (
    <>
      {loading === ELoadingStatus.Failed && error && (
        <PopupPortal>
          <Popup text={error.errorMessage} showTime={3} />
        </PopupPortal>
      )}
      {children}
    </>
  );
};

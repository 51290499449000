import React from 'react';
import ReactDOM from 'react-dom';

interface PopupPortalProps {
  children: React.ReactNode;
  callback?: () => void;
}

export const PopupPortal: React.FC<PopupPortalProps> = ({ children }) => {
  return ReactDOM.createPortal(
    <div>{children}</div>,
    document.getElementById('popup-root') as HTMLElement,
  );
};

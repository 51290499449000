import { batch } from 'react-redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';

import { RootState, AppThunk } from '../store';
import { INotificationItem } from '@http/models/notification-item';
import { v1 } from '@api/v1';

interface INotificationListState {
  loading: boolean;
  items: INotificationItem[];
  total: number;
  page: number;
  error?: string;
}

const initialState: INotificationListState = {
  loading: true,
  items: [],
  total: 0,
  page: 1,
};

const slice = createSlice({
  name: 'notificationList',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setItems: (state, action: PayloadAction<INotificationItem[]>) => {
      state.items = action.payload;
    },
    addItems: (state, action: PayloadAction<INotificationItem[]>) => {
      state.items = uniqBy([...state.items, ...action.payload], 'date');
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

const { setLoading, setItems, setTotal, setError, setPage, addItems } = slice.actions;

const notificationList = {
  ...slice.actions,
  selectLoading: (state: RootState) => state.notificationList.loading,
  selectItems: (state: RootState) => state.notificationList.items,
  selectError: (state: RootState) => state.notificationList.error,
  selectTotal: (state: RootState) => state.notificationList.total,
  loadData:
    (loadMore?: boolean): AppThunk =>
    async (dispatch, getState) => {
      const state = getState();
      try {
        dispatch(setError());
        const response = await v1.notification.get({
          page: loadMore ? state.notificationList.page : 1,
          take: 25,
        });
        if (response.errorCode) {
          dispatch(setError(response.errorMsg));
          return;
        }

        batch(() => {
          dispatch(setPage(loadMore ? state.notificationList.page + 1 : 1));
          dispatch(loadMore ? addItems(response.items) : setItems(response.items));
          dispatch(setTotal(response.total));
        });
      } finally {
        dispatch(setLoading(false));
      }
    },
};

export const notificationListReducer = slice.reducer;
export default notificationList;

import React, { useCallback } from 'react';
import { RColor } from '../../../types';
import styles from './spinner.module.scss';

interface SpinnerProps {
  className?: string;
  color?: RColor;
  type:
    | 'rotating-plane'
    | 'chasing-circle'
    | 'double-bounce'
    | 'wave'
    | 'ring'
    | 'dual-ring'
    | 'wandering-cubes'
    | 'pulse'
    | 'chasing-dots'
    | 'three-bounce'
    | 'circle'
    | 'cube-grid'
    | 'fading-circle'
    | 'folding-cube'
    | 'rotating-semicircle';
}

export default function Spinner(props: SpinnerProps) {
  const getClassName = useCallback(
    (cls: string) => (props.className ? `${styles[cls]} ${props.className}` : styles[cls]),
    [props.className]
  );

  const style: any = { '--spinner-color': props.color ? `var(--${props.color})` : 'var(--dark-primary)' };

  switch (props.type) {
    case 'rotating-plane':
      return <div className={getClassName('rotating-plane')} style={style} />;

    case 'chasing-circle':
      return (
        <div className={getClassName('chasing-circle')}>
          <div className={styles['sk-chase-dot']} style={style} />
          <div className={styles['sk-chase-dot']} style={style} />
          <div className={styles['sk-chase-dot']} style={style} />
          <div className={styles['sk-chase-dot']} style={style} />
          <div className={styles['sk-chase-dot']} style={style} />
          <div className={styles['sk-chase-dot']} style={style} />
        </div>
      );

    case 'double-bounce':
      return (
        <div className={getClassName('double-bounce')}>
          <div className={styles['double-bounce1']} style={style} />
          <div className={styles['double-bounce2']} style={style} />
        </div>
      );

    case 'wave':
      return (
        <div className={getClassName('wave')}>
          <div className={styles['rect1']} style={style} />
          <div className={styles['rect2']} style={style} />
          <div className={styles['rect3']} style={style} />
          <div className={styles['rect4']} style={style} />
          <div className={styles['rect5']} style={style} />
        </div>
      );

    case 'wandering-cubes':
      return (
        <div className={getClassName('wandering-cubes')}>
          <div className={styles['cube1']} style={style} />
          <div className={styles['cube2']} style={style} />
        </div>
      );

    case 'pulse':
      return <div className={getClassName('pulse')} style={style} />;

    case 'chasing-dots':
      return (
        <div className={getClassName('chasing-dots')}>
          <div className={styles['dot1']} style={style} />
          <div className={styles['dot2']} style={style} />
        </div>
      );

    case 'three-bounce':
      return (
        <div className={getClassName('three-bounce')}>
          <div className={styles['bounce1']} style={style} />
          <div className={styles['bounce2']} style={style} />
          <div className={styles['bounce3']} style={style} />
        </div>
      );

    case 'circle':
      return (
        <div className={getClassName('circle')}>
          <div className={styles['circle1']} style={style} />
          <div className={styles['circle2']} style={style} />
          <div className={styles['circle3']} style={style} />
          <div className={styles['circle4']} style={style} />
          <div className={styles['circle5']} style={style} />
          <div className={styles['circle6']} style={style} />
          <div className={styles['circle7']} style={style} />
          <div className={styles['circle8']} style={style} />
          <div className={styles['circle9']} style={style} />
          <div className={styles['circle10']} style={style} />
          <div className={styles['circle11']} style={style} />
          <div className={styles['circle12']} style={style} />
        </div>
      );

    case 'cube-grid':
      return (
        <div className={getClassName('cube-grid')}>
          <div className={styles['cube1']} style={style} />
          <div className={styles['cube2']} style={style} />
          <div className={styles['cube3']} style={style} />
          <div className={styles['cube4']} style={style} />
          <div className={styles['cube5']} style={style} />
          <div className={styles['cube6']} style={style} />
          <div className={styles['cube7']} style={style} />
          <div className={styles['cube8']} style={style} />
          <div className={styles['cube9']} style={style} />
        </div>
      );

    case 'ring':
      return (
        <div className={getClassName('ring')}>
          <div className={styles['circle1']}></div>
          <div className={styles['circle2']}></div>
          <div className={styles['circle3']}></div>
          <div className={styles['circle4']}></div>
        </div>
      );

    case 'dual-ring':
      return (
        <div className={getClassName('dual-ring')}></div>
      );

    case 'fading-circle':
      return (
        <div className={getClassName('fading-circle')}>
          <div className={styles['circle1']} style={style} />
          <div className={styles['circle2']} style={style} />
          <div className={styles['circle3']} style={style} />
          <div className={styles['circle4']} style={style} />
          <div className={styles['circle5']} style={style} />
          <div className={styles['circle6']} style={style} />
          <div className={styles['circle7']} style={style} />
          <div className={styles['circle8']} style={style} />
          <div className={styles['circle9']} style={style} />
          <div className={styles['circle10']} style={style} />
          <div className={styles['circle11']} style={style} />
          <div className={styles['circle12']} style={style} />
        </div>
      );

    case 'folding-cube':
      return (
        <div className={getClassName('folding-cube')}>
          <div className={styles['cube1']} style={style} />
          <div className={styles['cube2']} style={style} />
          <div className={styles['cube4']} style={style} />
          <div className={styles['cube3']} style={style} />
        </div>
      );

    case 'rotating-semicircle':
      return (
        <div className={getClassName('rotating-semicircle')} style={style}>
          <svg viewBox="-4 -4 48 48">
            <circle cx="20" cy="20" r="20" />
          </svg>
        </div>
      );

    default:
      return <></>;
  }
}
